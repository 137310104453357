import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import { useState } from "react"
import CalendlyButton from "../components/calendly"
import SEO from "../components/seo"

const ContactPage = () => {
    const [email, setEmail] = useState<string | null>(null)
    const [message, setMessage] = useState<string | null>(null)
    const [name, setName] = useState<string | null>(null)
    const [formSent, setFormSent] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [timer, setTimer] = useState<Date | null>(null)

  const startTimer = () => {
    if (!timer) setTimer(new Date())
  }

  const submit = (e: any) => {
    e.preventDefault()

    if (formSent || !timer || (new Date().getTime() - timer.getTime()) / 1000 < 3) {
        return
    } 

    const XHR = new XMLHttpRequest()
    XHR.addEventListener("load", () => {
      setFormSent(true)
    })

    XHR.open(
      "POST",
      "https://potax7wj94.execute-api.eu-central-1.amazonaws.com/contact-us"
    )
    XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
    XHR.send(JSON.stringify({ email: email, message: message, name: name }))
    setFormSubmitted(true)
  }

    return (
        <Layout>
            <SEO title="Contact us" />
            <section className="bg-white min-h-screen">
                <div className="items-center max-w-screen-xl h-full px-4 py-24 mx-auto xl:pt-32">
                    <h2 className="text-4xl tracking-tight font-extrabold text-gray-900">Contact us</h2>
                    <div className="h-full lg:grid lg:grid-cols-8 lg:gap-16 xl:gap-24 py-8 mx-auto">
                        <div className="col-span-4">
                            <div className="divide-y">
                                <div className="mb-8">
                                    <p className="mb-8 font-light text-gray-500 sm:text-xl">Do you want to see how the software works? Just schedule a demo and we will show you all you need to know.</p>
                                    <CalendlyButton />
                                </div>
                                
                                {formSent && (
                                    <div>
                                        <p className="mt-8 font-light text-gray-500 sm:text-xl">Thanks for contacting us! We'll get back to you as soon as possible.</p>
                                    </div>
                                )}

                                {!formSent && (
                                <div>
                                    <p className="mt-8 font-light text-gray-500 sm:text-xl">
                                        <b>Other questions?</b> 
                                    </p>
                                    <p className="font-light text-gray-500 sm:text-xl">Send us a message and we will get back to you as soon as possible.</p>
                                    <form onSubmit={submit} method="POST" className="mt-4 space-y-8">
                                        <div className="flex flex-wrap">
                                            <div className="w-full md:w-1/2 sm:pr-3 mb-6 md:mb-0">
                                                <label htmlFor="contact-name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
                                                <input
                                                    type="text" 
                                                    name="contact-name"
                                                    id="contact-name" 
                                                    className="appearance-none block w-full p-2.5 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500" 
                                                    placeholder="Your name" 
                                                    required
                                                    autoComplete="off"
                                                    onChange={e => setName(e.target.value)}
                                                />
                                            </div>
                                            <div className="w-full md:w-1/2 mb-6 md:mb-0">
                                                <label htmlFor="contact-email" className="block mb-2 text-sm font-medium text-gray-900">Email</label>
                                                <input 
                                                    type="email"
                                                    name="email"
                                                    id="contact-email"
                                                    className="appearance-none block w-full p-2.5 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500"
                                                    placeholder="Your email"
                                                    required
                                                    onFocus={startTimer}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Message</label>
                                            <textarea
                                                name="message"
                                                className="min-h-[200px] resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-purple-500 focus:border-purple-500"
                                                placeholder="Your message"
                                                required
                                                onFocus={startTimer}
                                                onChange={e => setMessage(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <button
                                            disabled={formSubmitted}
                                            type="submit" 
                                            className="text-white w-full sm:w-auto bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none"
                                        >
                                            Send message
                                        </button>
                                    </form>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="col-span-4 h-full mt-4 sm:mt-0">
                            <iframe 
                                className="rounded-lg"
                                title="Where to find us!"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d576943.5955774367!2d12.457693165436078!3d55.6074796805148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a3e6bbe82b21%3A0x90690fc567c4b9d6!2sBredgatan%204%2C%20211%2030%20Malm%C3%B6!5e0!3m2!1sen!2sse!4v1638345876571!5m2!1sen!2sse"
                                width="100%"
                                height="90%"
                                allowFullScreen={false}
                                aria-hidden="false"
                                tabIndex={0}>
                            </iframe>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactPage

export const Head: HeadFC = () => <title>BoxLoading</title>